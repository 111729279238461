import * as React from "react";
import Layout from "../components/layout.js";
import "../styles/forms.css";

export default function ContactPage() {
  return (
    <Layout title="Contact us" description="Contact The Design Group">
      <form
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="contact"
        action="/success"
      >
        <input type="hidden" name="form-name" value="contact" />
        <p class="hidden">
          <label>
            Don’t fill this out if you’re human: <input name="bot-field" />
          </label>
        </p>
        <div class="form-row">
          <label for="email" class="required">
            Email:
          </label>
          <input type="email" name="email" id="email" required="required" />
        </div>
        <div class="form-row">
          <label for="message">Message:</label>
          <textarea name="message" id="message" rows="5" />
        </div>
        <div class="form-row checkbox">
          <input type="checkbox" name="subscribe" id="subscribeNews" />
          <label for="subscribeNews">
            I would like to receive the newsletter
          </label>
        </div>
        <p class="privacy-statement">
          The Design Group will only use your email address to reply to your
          message and to send you issues of our newsletter (if you have chosen
          to receive this). You can reply to the newsletter at any time to tell
          us that you wish to unsubscribe.
        </p>
        <div class="form-row submit">
          <button type="submit" class="primary">
            Send
          </button>
          <input type="reset" value="Clear" />
        </div>
        <p class="form-info">* indicates a required field.</p>
      </form>
    </Layout>
  );
}
